@import url('https://fonts.googleapis.com/css2?family=Kurale&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html{
    font-family: 'Raleway', sans-serif; 
  }
}


html,
body {
  margin: 0;
  padding:0;
  overflow-x:hidden;
  
   
}

a {
  color: inherit;
  text-decoration: none;
}

*{
  box-sizing: border-box;
}

.active{
  color:white;
  border: 1px blue solid;
  border-radius: 50%;
  padding: 2px;
  background-color: blue;
  
}
.small-active{
  color: blue; 
}